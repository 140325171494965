<template>
  <div class="readme-article">
    <h1 id="回访跟进">
      二、回访跟进
    </h1>
    <h2 id="今日预约线索">1.今日预约线索</h2>
    <p>显示预约今日回访的线索列表，支持筛选查询</p>
    <p>
      <img src="@/assets/img/recruit/2-1.png">
    </p>
    <h2 id="今日已联系">2.今日已联系</h2>
    <p>显示预约今日已联系的线索列表，支持筛选查询</p>
    <p>
      <img src="@/assets/img/recruit/2-2.png">
    </p>
    <h2 id="近期预约线索">3.近期预约线索</h2>
    <p>查看近期的预约线索，可通过时间区间查询</p>
    <p>
      <img src="@/assets/img/recruit/2-3.png">
    </p>
    <h2 id="近期已联系">4.近期已联系</h2>
    <p>查看近期已联系的预约线索，可通过时间区间查询</p>
    <p>
      <img src="@/assets/img/recruit/2-4.png">
    </p>
    <h2 id="来访登记查询">5.来访登记查询</h2>
    <p>查看来访登记信息,包含状态，来访时间，接待人等信息</p>
    <p>
      <img src="@/assets/img/recruit/2-5.png">
    </p>
    <h2 id="回访记录查询">6.回访记录查询</h2>
    <p>查看回访记录信息,包含等级，回访时间，联系结果等信息</p>
    <p>
      <img src="@/assets/img/recruit/2-6.png">
    </p>
    <h2 id="线索电话记录">7.线索电话记录</h2>
    <p>查看线索电话记录</p>
    <p>
      <img src="@/assets/img/recruit/2-7.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Recruit2-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>